  'use strict'
  
  # スムーススクロール関数モーション定義
  jQuery.extend(
    jQuery.easing,{
      easeInOutCirc:
        (x, t, b, c, d) ->
          if (t/=d/2) < 1
            return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
          c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
    }
  )
  
  $('.js-anchor').find('a[href^="#"]'+'a[href!="#"]').on('click.anchorScroll'
    (e)->
      if window.matchMedia('(max-width:750px)').matches
        scrollPos = $($(this).attr('href')).offset().top - $('.p-header').outerHeight(true)
      else
        scrollPos = $($(this).attr('href')).offset().top - 20
  
      if $('.p-anchor .js-headroom').length isnt 0
        scrollPos -= $('.p-anchor .js-headroom').outerHeight()
      else
        scrollPos -= 10
      if $('.p-header .js-headroom').length isnt 0
        scrollPos -= $('.p-header .js-headroom').outerHeight()
  
      $(
        if (navigator.userAgent.indexOf('Opera') isnt -1)
        then (
          if document.compatMode is 'BackCompat'
          then 'body'
          else 'html'
        )
        else 'html,body'
      ).animate({
        scrollTop: scrollPos
      },{
        easing:'easeInOutCirc',
        duration:1000
      })
  
      e.preventDefault()
      return
  )
  
  if $('.p-anchor .js-headroom').length isnt 0
    $(window).on('load', ()->
      $('.p-anchor .js-headroom').headroom({
        offset: ($('.p-lead').offset().top + $('.p-lead').outerHeight(true)) - $('.p-header .js-headroom').outerHeight()
      })
    )
  
  if location.href.indexOf('#') isnt -1
    scrollPos = $(location.href.slice(location.href.indexOf('#'), location.href.length)).offset().top
  
    if $('.p-anchor .js-headroom').length isnt 0
      scrollPos -= $('.p-anchor .js-headroom').outerHeight()
    else
      scrollPos -= 10
    if $('.p-header .js-headroom').length isnt 0
      scrollPos -= $('.p-header .js-headroom').outerHeight()
  
    $(
      if (navigator.userAgent.indexOf('Opera') isnt -1)
      then (
        if document.compatMode is 'BackCompat'
        then 'body'
        else 'html'
      )
      else 'html,body'
    ).animate({
      scrollTop: scrollPos
    },{
      easing:'easeInOutCirc',
      duration:1
    })
  
    $(window).on('load', ()->
      scrollPos = $(location.href.slice(location.href.indexOf('#'), location.href.length)).offset().top
  
      if $('.p-anchor .js-headroom').length isnt 0
        scrollPos -= $('.p-anchor .js-headroom').outerHeight()
      else
        scrollPos -= 10
      if $('.p-header .js-headroom').length isnt 0
        scrollPos -= $('.p-header .js-headroom').outerHeight()
  
      $(
        if (navigator.userAgent.indexOf('Opera') isnt -1)
        then (
          if document.compatMode is 'BackCompat'
          then 'body'
          else 'html'
        )
        else 'html,body'
      ).animate({
        scrollTop: scrollPos
      },{
        easing:'easeInOutCirc',
        duration:1
      })
    )
  